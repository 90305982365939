<template>
  <div>
    <a-row>
      <a-col :span="2" />
      <a-col :span="8">
        <div style="width: 70%;margin: 30px 0px;">
          <h1 class="help_base_info" style="font-weight: 600;font-size: 60px;margin-top: 60px;">
            帮我找房
          </h1>
          <div class="help_base_info" style="margin-top: 30px;font-size: 20px;">
            我们的团队可以帮您:
          </div>
          <div class="help_base_info" style="margin-top: 60px;font-size: 18px;">
            <img src="@/assets/checkMark.png" style="height: 18px;margin-top: 4px;margin-right: 6px;"
              alt="">快速租赁服务，找到最适合您的房源！
          </div>
          <div class="help_base_info" style="margin-top: 30px;font-size: 18px;">
            <img src="@/assets/checkMark.png" style="height: 18px;margin-top: 4px;margin-right: 6px;"
              alt="">专业团队，为您的房屋需求提供最优解决方案！
          </div>
          <div class="help_base_info" style="margin-top: 30px;font-size: 18px;">
            <img src="@/assets/checkMark.png" style="height: 18px;margin-top: 4px;margin-right: 6px;"
              alt="">多种房源，灵活租赁，满足您的个性化业务需求！
          </div>
          <div class="help_base_info" style="margin-top: 30px;font-size: 18px;">
            <img src="@/assets/checkMark.png" style="height: 18px;margin-top: 4px;margin-right: 6px;"
              alt="">一站式房屋租赁服务，让您的租赁更轻松！
          </div>
          <div class="help_base_info" style="margin-top: 60px;font-size: 24px;">
            业务咨询：021-57316613
          </div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="form_background">
          <!-- 基础信息 -->
          <div class="ant-row-flex" style="margin:0px 4px 22px 0px;">
            <a-space align="center">
              <div class="title_icon"></div>
              <div class="ant-descriptions-title" style="font-size: 18px;margin-bottom: 0px;">基础信息</div>
            </a-space>
          </div>
          <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="客户姓名">
              <a-input v-model="form.customName" placeholder="客户姓名" size="large" />
            </a-form-model-item>
            <a-form-model-item label="客户类别">
              <a-radio-group v-model="form.customStatus">
                <a-radio value="个人">
                  个人
                </a-radio>
                <a-radio value="企业">
                  企业
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="联系方式">
              <a-input v-model="form.linkPhone" placeholder="联系方式" size="large" />
            </a-form-model-item>

            <!-- 基础信息 -->
            <div class="ant-row-flex" style="margin:0px 4px 22px 0px">
              <a-space align="center">
                <div class="title_icon"></div>
                <div class="ant-descriptions-title" style="font-size: 18px;margin-bottom: 0px;">房源信息</div>
              </a-space>
            </div>
            <a-form-model-item label="区域位置" style="text-indent: 8px;margin-bottom: 40px;">
              <a-select size="large" v-model="form.place" placeholder="请选择区域">
                <a-select-option value="上海市区房源">
                  上海市区房源
                </a-select-option>
                <a-select-option value="朱泾地区">
                  朱泾地区
                </a-select-option>
                <a-select-option value="石化地区">
                  石化地区
                </a-select-option>
                <a-select-option value="枫泾地区">
                  枫泾地区
                </a-select-option>
                <a-select-option value="亭林地区">
                  亭林地区
                </a-select-option>
                <a-select-option value="张堰地区">
                  张堰地区
                </a-select-option>
                <a-select-option value="吕巷地区">
                  吕巷地区
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="房源类型" style="text-indent: 8px;margin: 40px 0px">
              <a-select size="large" v-model="form.houseStatus" placeholder="请选择房源类型">
                <a-select-option value="商铺">
                  商铺
                </a-select-option>
                <a-select-option value="写字楼">
                  写字楼
                </a-select-option>
                <a-select-option value="综合体">
                  综合体
                </a-select-option>
                <a-select-option value="厂房">
                  厂房
                </a-select-option>
                <a-select-option value="其他商业">
                  其他商业
                </a-select-option>
                <a-select-option value="居住">
                  居住
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="租赁面积" style="margin: 40px 0px">

              <a-input-group compact>

                <a-input v-model="form.area" placeholder="预期租赁面积" suffix="平方米" size="large" />
              </a-input-group>

            </a-form-model-item>

            <a-form-model-item label="预期租金" style="margin: 40px 0px">

              <a-input-group compact>

                <a-input style=" width: 44%; text-align: center" placeholder="最低租金" suffix="元/㎡·日" size="large"
                  v-model="form.rentLow" />
                <a-input style=" width: 12%; border-left: 0; pointer-events: none; backgroundColor: #fff"
                  placeholder="~" size="large" disabled />
                <a-input style="width: 44%; text-align: center; border-left: 0" placeholder="最高租金" suffix="元/㎡·日"
                  size="large" v-model="form.rentHigh" />
              </a-input-group>

            </a-form-model-item>

            <a-form-model-item label="备注信息" style="margin: 40px 0px">
              <a-input v-model="form.remark" placeholder="备注信息" size="large" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
              <a-button style="width: 100%; height: 50px;" type="primary" @click="onSubmit()">
                立即申请
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-col>


    </a-row>


  </div>

</template>
<script>
import { helpFindHouse } from "@/request/api";
export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      form: {
        customName: undefined,
        customStatus: "个人",
        linkPhone: undefined,
        place: undefined,
        houseStatus: undefined,
        area: undefined,
        rentLow: undefined,
        rentHigh: undefined,
        remark: undefined,
      },
    };
  },
  methods: {
    onSubmit() {
      helpFindHouse({
        userId: -1,
        customName: this.form.customName,
        customStatus: this.form.customStatus,
        linkPhone: this.form.linkPhone,
        place: this.form.place,
        houseStatus: this.form.houseStatus,
        area: this.form.area,
        rentLow: this.form.rentLow,
        rentHigh: this.form.rentHigh,
        remark: this.form.remark
      }).then((res) => {
        if (res.data.code == "200") {
          this.$notification['success']({
            message: '申请成功',
            description:
              '帮我找房',
          });
        }

      });
    },

  },
};
</script>

<style lang="less" scoped>
.title_icon {
  width: 8px;
  height: 26px;
  margin-right: 10px;
  background: #007aff;
}

.help_base_info {
  --tw-text-opacity: 1;
  color: rgb(29 29 29/var(--tw-text-opacity));
  justify-content: center;
  display: flex;
}

.form_background {
  width: 100%;
  margin: 30px 0px;
  border-radius: 1rem;
  border-width: 0;
  --tw-border-opacity: 1;
  border-top: 8px #007aff;
  border-style: solid;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
  padding: 3rem;
  --tw-shadow: 0px 20px 25px -5px rgba(64, 63, 118, .1), 0px 10px 10px -5px rgba(64, 63, 118, .04);
  --tw-shadow-colored: 0px 20px 25px -5px var(--tw-shadow-color), 0px 10px 10px -5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>